import React from 'react';
import styled, { keyframes } from 'styled-components';

interface GuestSpinnerOriginalProps {
  className?: string;
  color: string;
}

const GuestSpinnerOriginal: React.FC<GuestSpinnerOriginalProps> = () => {
  return (
    <LoaderWrapper>
      <LabelDiv>Please wait...</LabelDiv>
      <LoadingDiv />
    </LoaderWrapper>
  );
};

const loadingAnimation = keyframes`
    0% {
        left: -25%;
    }
    100% {
        left: 70%;
    }
`;

const bitAnimation = keyframes`
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
`;

const LoaderWrapper = styled.div`
  width: 350px; /* Ensure this container has a non-zero size */
  height: 180px; /* Ensure this container has a non-zero size */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px;
`;

const LoadingDiv = styled.div`
  width: 100%;
  height: 10px;
  background: lightgrey;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 10px;
    background: #040c2e;
    border-radius: 10px;
    z-index: 1;
    animation: ${loadingAnimation} 0.6s alternate infinite;
  }
`;

const LabelDiv = styled.div`
  color: #040c2e;
  font-size: 18px;
  animation: ${bitAnimation} 0.6s alternate infinite;
`;

export const GuestGlobalSpinner = GuestSpinnerOriginal;
