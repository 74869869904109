import React, { useEffect, useMemo, useState } from 'react';
import { NFTCardHeader } from '@app/components/nft-dashboard/common/NFTCardHeader/NFTCardHeader';
import { TrendingCollection } from '@app/components/nft-dashboard/trending-collections/collection/TrendingCollection';
import * as S from './TrendingCollections.styles';
import { getQRCodesData, QrCodeRow } from '@app/api/qr-codes.api';

export const TrendingCollections: React.FC = () => {
  const [trending, setTrending] = useState<QrCodeRow[]>([]);

  useEffect(() => {
    getQRCodesData({
      pageSize: 50,
    }).then((res) => setTrending(res.data));
  }, []);

  const trendingList = useMemo(() => {
    return {
      tablet: trending.map((item, index) => (
        <div key={index}>
          <S.CardWrapper>
            <TrendingCollection {...item} />
          </S.CardWrapper>
        </div>
      )),
    };
  }, [trending]);

  return (
    <>
      <NFTCardHeader title={'QR Codes'}></NFTCardHeader>

      <S.SectionWrapper style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto' }}>
        {trendingList.tablet}
      </S.SectionWrapper>
    </>
  );
};
