import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';
import { readToken } from '@app/services/localStorage.service';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };
  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error.response?.status === 401) {
    window.location.href = '/auth/login';
  }
});

// httpApi.interceptors.response.use((response) => {
//   if(response.status === 401) {
//     window.location.href = '/auth/login';
//   }
//   return response;
// }, (error) => {
//   if (error.response && error.response.data) {
//       return Promise.reject(error.response.data);
//   }
//   return Promise.reject(error.message);
// });

export interface ProfileUpdateRequest {
  name: string;
  email: string;
  phoneNumber: number;
  address: string;
}

export interface UpdatePasswordRequest {
  current_password: string;
  password: string;
  password_confirmation: string;
}

export interface RegistrationResponse {
  success: boolean;
}

export const currentUser = (): Promise<UserModel> => {
  return httpApi.get<UserModel>('/auth/current-user').then(({ data }) => data);
};

export const updatePassword = (updatePasswordData: UpdatePasswordRequest): Promise<RegistrationResponse> => {
  return httpApi.post<RegistrationResponse>('/update-password', { ...updatePasswordData }).then(({ data }) => data);
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateImage = (data: FormData, config: AxiosRequestConfig<any>): Promise<RegistrationResponse> => {
  return httpApi.post<RegistrationResponse>('/update-photo', data, config).then(({ data }) => data);
};
