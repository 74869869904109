import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const Rating = styled.div`
  margin-bottom: 1.875rem;
  @media only screen and ${media.xs} {
    margin-bottom: 1.5625rem;
  }

  @media only screen and ${media.md} {
    margin-bottom: 1.75rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1.875rem;
  }
`;
