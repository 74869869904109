import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSignUp, saveLocations } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import ReactGA from 'react-ga4';

interface SignUpFormData {
  firstName: string;
  lastName?: string;
  email: string;
  phoneNumber: number;
  city?: string;
  qrCode?: string;
}

const initValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  city: '',
};

export const SignUpForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    localStorage.removeItem('registrationId');
    localStorage.removeItem('qrCodeSlug');

    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true, // remove leading "?"
    });
    if (!queryParams.view) {
      navigate('/server-error');
    }

    // Google Analytics on page view
    if (queryParams.view) {
      ReactGA.send({
        hitType: 'page-view',
        page: '/auth/registration/' + queryParams.view,
        title: 'Registration Page',
      });

      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          saveLocations({
            qrCode: String(queryParams.view),
            latitude: String(position.coords.latitude),
            longitude: String(position.coords.longitude),
          }),
        );
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (values: SignUpFormData) => {
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true, // remove leading "?"
    });

    const payload = {
      ...values,
      qrCode: queryParams?.view as string,
    };

    setLoading(true);
    dispatch(doSignUp(payload))
      .unwrap()
      .then((response) => {
        localStorage.setItem('registrationId', String(response.id));
        localStorage.setItem('qrCodeSlug', String(queryParams?.view));
        // notificationController.success({
        //   message: t('auth.signUpSuccessMessage'),
        //   description: t('auth.signUpSuccessDescription'),
        // });
        setLoading(false);
        if (response?.link) {
          const link = document.createElement('a');
          link.href = response.link;
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          notificationController.error({ message: 'Oops, something went wrong!' });
        }
      })
      .catch(() => {
        notificationController.error({ message: 'Oops, something went wrong!' });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>{'Fill Up to experience AR'}</S.Title>
        <Auth.FormItem
          name="firstName"
          label={t('common.name')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.name')} />
        </Auth.FormItem>
        {/*<Auth.FormItem*/}
        {/*  name="lastName"*/}
        {/*  label={t('common.lastName')}*/}
        {/*  rules={[{ required: true, message: t('common.requiredField') }]}*/}
        {/*>*/}
        {/*  <Auth.FormInput placeholder={t('common.lastName')} />*/}
        {/*</Auth.FormItem>*/}
        <Auth.FormItem
          name="phoneNumber"
          label={t('common.phoneNumber')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              pattern: /^[6-9]\d{9}$/gi,
              message: t('profile.nav.personalInfo.wrongNumber'),
            },
            // () => ({
            //   validator(_, value) {
            //     if (!value || isValidPhoneNumber('+91' + value)) {
            //       return Promise.resolve();
            //     }
            //     return Promise.reject(new Error(t('profile.nav.personalInfo.wrongNumber')));
            //   },
            // }),
          ]}
        >
          <Auth.FormInput placeholder={t('common.phoneNumber')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        {/*<Auth.FormItem*/}
        {/*  name="city"*/}
        {/*  label={t('common.city')}*/}
        {/*  rules={[{ required: true, message: t('common.requiredField') }]}*/}
        {/*>*/}
        {/*  <Auth.FormInput placeholder={t('common.city')} />*/}
        {/*</Auth.FormItem>*/}
        {/*<Auth.FormItem*/}
        {/*  label={t('common.password')}*/}
        {/*  name="password"*/}
        {/*  rules={[{ required: true, message: t('common.requiredField') }]}*/}
        {/*>*/}
        {/*  <Auth.FormInputPassword placeholder={t('common.password')} />*/}
        {/*</Auth.FormItem>*/}
        {/*<Auth.FormItem*/}
        {/*  label={t('common.confirmPassword')}*/}
        {/*  name="confirmPassword"*/}
        {/*  dependencies={['password']}*/}
        {/*  rules={[*/}
        {/*    { required: true, message: t('common.requiredField') },*/}
        {/*    ({ getFieldValue }) => ({*/}
        {/*      validator(_, value) {*/}
        {/*        if (!value || getFieldValue('password') === value) {*/}
        {/*          return Promise.resolve();*/}
        {/*        }*/}
        {/*        return Promise.reject(new Error(t('common.confirmPasswordError')));*/}
        {/*      },*/}
        {/*    }),*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />*/}
        {/*</Auth.FormItem>*/}
        {/*<Auth.ActionsWrapper>*/}
        {/*  <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>*/}
        {/*    <Auth.FormCheckbox>*/}
        {/*      <Auth.Text>*/}
        {/*        {t('signup.agree')}{' '}*/}
        {/*        <Link to="/" target={'_blank'}>*/}
        {/*          <Auth.LinkText>{t('signup.termOfUse')}</Auth.LinkText>*/}
        {/*        </Link>{' '}*/}
        {/*        and{' '}*/}
        {/*        <Link to="/" target={'_blank'}>*/}
        {/*          <Auth.LinkText>{t('signup.privacyOPolicy')}</Auth.LinkText>*/}
        {/*        </Link>*/}
        {/*      </Auth.Text>*/}
        {/*    </Auth.FormCheckbox>*/}
        {/*  </BaseForm.Item>*/}
        {/*</Auth.ActionsWrapper>*/}
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {'Go'}
          </Auth.SubmitButton>
        </BaseForm.Item>
        {/*<BaseForm.Item noStyle>*/}
        {/*  <Auth.SocialButton type="default" htmlType="submit">*/}
        {/*    <Auth.SocialIconWrapper>*/}
        {/*      <GoogleIcon />*/}
        {/*    </Auth.SocialIconWrapper>*/}
        {/*    {t('signup.googleLink')}*/}
        {/*  </Auth.SocialButton>*/}
        {/*</BaseForm.Item>*/}
        {/*<BaseForm.Item noStyle>*/}
        {/*  <Auth.SocialButton type="default" htmlType="submit">*/}
        {/*    <Auth.SocialIconWrapper>*/}
        {/*      <FacebookIcon />*/}
        {/*    </Auth.SocialIconWrapper>*/}
        {/*    {t('signup.facebookLink')}*/}
        {/*  </Auth.SocialButton>*/}
        {/*</BaseForm.Item>*/}
        {/*<Auth.FooterWrapper>*/}
        {/*  <Auth.Text>*/}
        {/*    {t('signup.alreadyHaveAccount')}{' '}*/}
        {/*    <Link to="/auth/login">*/}
        {/*      <Auth.LinkText>{t('common.here')}</Auth.LinkText>*/}
        {/*    </Link>*/}
        {/*  </Auth.Text>*/}
        {/*</Auth.FooterWrapper>*/}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
