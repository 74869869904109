import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { SignUpForm } from '@app/components/auth/SignUpForm/SignUpForm';
import { getQrCode } from '@app/api/qr-codes.api';
import { GuestLoading } from '@app/components/common/GuestLoading';

const SignUpPage: React.FC = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const view = queryParameters.get('view')!;

  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    getQrCode(view).then((res) => {
      if (res.show_form) {
        setIsLoading(false);
      } else {
        const link = document.createElement('a');
        link.href = res.ar_link;
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });

    fetch(view);
  }, [view]);

  return (
    <>
      {isLoading ? (
        <GuestLoading />
      ) : (
        <>
          <PageTitle>{t('common.registration')}</PageTitle>
          <SignUpForm />
        </>
      )}
    </>
  );
};

export default SignUpPage;
