import React from 'react';
import { UserOutlined, QrcodeOutlined, ContactsOutlined, DashboardOutlined } from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  needSuperAdminAccess?: boolean;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.dashboard',
    key: 'nft-dashboard',
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'common.companies',
    key: 'companies',
    url: '/companies',
    needSuperAdminAccess: true,
    icon: <UserOutlined />,
  },
  {
    title: 'common.qrCodes',
    key: 'qr-codes',
    url: '/qr-codes',
    icon: <QrcodeOutlined />,
  },
  {
    title: 'common.registrations',
    key: 'registrations',
    url: '/registrations',
    icon: <ContactsOutlined />,
  },
];
