import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ReviewForm } from '@app/components/auth/ReviewForm/ReviewForm';

const ReviewPage: React.FC = () => {
  return (
    <>
      <PageTitle>Review</PageTitle>
      <ReviewForm />
    </>
  );
};

export default ReviewPage;
