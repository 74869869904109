import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Avatar,message, Upload } from 'antd';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from '@app/components/common/Upload/Upload';
import { Button } from '@app/components/common/buttons/Button/Button';
import * as AS from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import { Avatar } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { updateImage } from '@app/api/user.api';
import { currentUserData } from '@app/store/slices/userSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import favLogo from 'assets/favicon.png';

export interface UploadChangeParam<T = UploadFile> {
  file: T;
  fileList: T[];
  event?: {
    percent: number;
  };
}

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const uploadImage = async (options: RcCustomRequestOptions) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        if (onProgress) {
          onProgress({ percent: percent });
        }
      },
    };
    fmData.append('image', file);
    fmData.append('type', 'profile');
    updateImage(fmData, config)
      .then(() => {
        if (onSuccess) {
          onSuccess('ok');
          dispatch(currentUserData());
        }
      })
      .catch((err) => {
        console.log('err', err);
        if (onError) {
          onError(err);
        }
      });
  };

  return profileData ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <Avatar shape="circle" src={profileData?.profile || favLogo} alt="Profile" />
      </S.ImgWrapper>
      <S.Title>{`${profileData?.name}`}</S.Title>
      <S.Subtitle>{profileData?.userName}</S.Subtitle>
      <AS.Card style={{ alignItems: 'center' }}>
        <Upload customRequest={uploadImage}>
          <Button icon={<UploadOutlined />}>{t('uploads.clickToUpload')}</Button>
        </Upload>
      </AS.Card>
    </S.Wrapper>
  ) : null;
};
