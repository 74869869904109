import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const MapWithMarkers = () => {
  const sampleLocations = [
    { lat: 23.0135966, lng: 72.514546 },
    { lat: 22.888523, lng: 72.48072 },
    { lat: 23.096881, lng: 72.544241 },
  ];

  return (
    <MapContainer center={[23.0201581, 72.4149333]} zoom={10} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; OpenStreetMap contributors"
      />
      {sampleLocations.map(({ lat, lng }) => (
        <Marker
          key={`${lat}-${lng}`}
          position={[lat, lng]}
          icon={L.icon({ iconUrl: 'https://unpkg.com/leaflet/dist/images/marker-icon.png' })}
        >
          <Popup>{'address'}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapWithMarkers;
