import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TrendingCollections } from '@app/components/nft-dashboard/trending-collections/TrendingCollections';
import * as S from './DashboardPage.styles';
import { getGoogleAnalytics } from '@app/api/qr-codes.api';
import CityDataChart from '@app/pages/DashboardPages/CityDataChart';
import DayDataChart from '@app/pages/DashboardPages/DayDataChart';
import MapWithMarkers from '@app/pages/DashboardPages/MapWithMarkers';

export interface CityData {
  date: string;
  city: string;
  pagePath: string;
  activeUsers: number;
}

const MatrixDashboardPage: React.FC = () => {
  const [data, setData] = useState<Array<CityData>>([]);

  // useEffect(() => {
  //   getGoogleAnalytics().then((res) => {
  //     setData(res.analytics);
  //   });
  // }, []);

  const layout = (
    <Row>
      <S.MatrixLayout id="desktop-content">
        <TrendingCollections />
      </S.MatrixLayout>
    </Row>
  );

  const analytics = (
    <>
      {/*<Row style={{ margin: '50px' }}>*/}
      {/*  <h1>Active Users by City</h1>*/}
      {/*  <CityDataChart data={data} />*/}
      {/*</Row>*/}

      {/*<Row style={{ margin: '50px' }}>*/}
      {/*  <h1>Daily Active Users</h1>*/}
      {/*  <DayDataChart data={data} />*/}
      {/*</Row>*/}

      <Row style={{ margin: '50px' }}>
        <h1>Users By Location</h1>
        <MapWithMarkers />
      </Row>
    </>
  );

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      {layout}

      {analytics}
    </>
  );
};

export default MatrixDashboardPage;
