import { Pagination, SuccessResponse } from '@app/api/types';
import axios, { AxiosError } from 'axios';
import { readToken } from '@app/services/localStorage.service';

const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error.response?.status === 401) {
    window.location.href = '/auth/login';
  }
});

// httpApi.interceptors.response.use((response) => {
//   if(response.status === 401) {
//     window.location.href = '/auth/login';
//   }
//   return response;
// }, (error) => {
//   if (error.response && error.response.data) {
//       return Promise.reject(error.response.data);
//   }
//   return Promise.reject(error.message);
// });

export interface CompanyUser {
  id: number;
  name: string;
  email: string;
}

export interface QrCodeRow {
  id: number;
  name: string;
  url: string;
  ar_link: string;
  qrType: string;
  description: string;
  totalRegistrations: number;
  user: CompanyUser | null;
  slug: string;
  show_form: boolean;
  qrCodeImage: string;
  thumb: string;
  background: string;
  qrImage: string;
}

export interface QrCodeType {
  id: string;
  label: string;
}

export interface QrCodesData {
  data: QrCodeRow[];
  pagination: Pagination;
}

export interface CreateRequest {
  name: string;
  url?: string;
  ar_link?: string;
  qr_type?: string;
  description: string;
  user_id: string | number;
}

export interface ShowRequest {
  slug: string;
}

export const getQRCodesData = (pagination: Pagination, extraParams = {}): Promise<QrCodesData> => {
  const params = {
    'page[number]': pagination.current,
    'page[size]': pagination.pageSize,
    forFilter: pagination.forFilter,
    ...extraParams,
  };
  return httpApi.get('qr-codes', { params }).then(({ data }) => data.qrCodes);
};

export const createQRCode = (createData: CreateRequest): Promise<SuccessResponse> => {
  return httpApi.post<SuccessResponse>('qr-codes', { ...createData }).then(({ data }) => data);
};

export const updateQRCode = (updateData: CreateRequest, id: string | number): Promise<SuccessResponse> => {
  return httpApi.put<SuccessResponse>('qr-codes/' + id, { ...updateData }).then(({ data }) => data);
};

export const getQrCode = (slug: string): Promise<QrCodeRow> => {
  return httpApi.get<QrCodeRow>('qr-codes/' + slug).then(({ data }) => data);
};

export const getQrCodeTypes = (): Promise<Array<QrCodeType>> => {
  return httpApi.get<Array<QrCodeType>>('qr-code-types').then(({ data }) => data);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getGoogleAnalytics = () => {
  return httpApi.get('analytics').then(({ data }) => data);
};
