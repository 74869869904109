import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
// import favLogo from 'assets/favicon.png';
// import favLogoDark from 'assets/favicon-dark.png';
import logo from 'assets/images/logo_white.png';
// import { useAppSelector } from '@app/hooks/reduxHooks';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { tabletOnly } = useResponsive();

  // const theme = useAppSelector((state) => state.theme.theme);
  // const user = useAppSelector((state) => state.user.user);
  //
  // const img = theme === 'dark' ? favLogoDark : favLogo;

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/">
        <img src={logo} alt="Matrixbrains" height={48} />
        {/*<S.BrandSpan>Matrixbrains</S.BrandSpan>*/}
      </S.SiderLogoLink>
      {tabletOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
