import React from 'react';
import { Avatar } from '@app/components/common/Avatar/Avatar';
import * as S from './TrendingCollection.styles';
import { QrCodeRow } from '@app/api/qr-codes.api';
import { useNavigate } from 'react-router-dom';

export const TrendingCollection: React.FC<QrCodeRow> = ({ id, name, totalRegistrations, thumb }) => {
  const navigate = useNavigate();

  return (
    <S.Card
      padding={0}
      $img={thumb || 'https://lightence-assets.s3.amazonaws.com/lightence-activity/unsplash_eHUMDkv4q1w_xchurr.webp'}
    >
      <S.CollectionImage
        src={thumb || 'https://lightence-assets.s3.amazonaws.com/lightence-activity/unsplash_eHUMDkv4q1w_xchurr.webp'}
        alt="nft"
      />
      <S.BidButton type="ghost" onClick={() => navigate('/registrations?qrCode=' + id)}>
        Visitors
      </S.BidButton>
      <S.NftCollectionInfo>
        <S.AuthorAvatarWrapper>
          <Avatar shape="circle" size={64} src={'https://www.pngrepo.com/png/235024/180/qr-code.png'} alt={name} />
        </S.AuthorAvatarWrapper>
        <S.InfoRow>
          <S.Title level={5}>{name}</S.Title>
        </S.InfoRow>
        <S.InfoRow>
          <S.OwnerText></S.OwnerText>
          <S.USDText>Registrations: {totalRegistrations}</S.USDText>
        </S.InfoRow>
      </S.NftCollectionInfo>
    </S.Card>
  );
};
