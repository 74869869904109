// import { httpApi } from '@app/api/http.api';
import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';
import { readToken } from '@app/services/localStorage.service';
import axios from 'axios';

const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };
  return config;
});

httpApi.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      window.location.href = '/auth/login';
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  },
);

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName?: string;
  email: string;
  city?: string;
  phoneNumber: number;
  qrCode?: string;
}

export interface SaveLocationRequest {
  latitude: string;
  longitude: string;
  qrCode?: string;
}

export interface ReviewRequest {
  rating: string | number;
  review: string;
  registration_id: string | null;
}

export interface ProfileUpdateRequest {
  name: string;
  email?: string;
  phoneNumber: number;
  address: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export interface RegistrationResponse {
  success: boolean;
  id: string | number;
  link?: string;
}

export interface ReviewResponse {
  success: boolean;
}

export interface ImpersonateRequest {
  user_id: number;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('/auth/login', { ...loginPayload }).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<RegistrationResponse> => {
  return httpApi.post<RegistrationResponse>('/registrations', { ...signUpData }).then(({ data }) => data);
};

export const saveLocation = (params: SaveLocationRequest): Promise<ReviewResponse> => {
  return httpApi.post<RegistrationResponse>('/locations', { ...params }).then(({ data }) => data);
};

export const review = (reviewData: ReviewRequest): Promise<ReviewResponse> => {
  return httpApi.post<ReviewResponse>('/reviews', { ...reviewData }).then(({ data }) => data);
};

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('auth/forgot-password', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);

export const profileUpdate = (profileData: ProfileUpdateRequest): Promise<RegistrationResponse> => {
  return httpApi.post<RegistrationResponse>('/update-profile', { ...profileData }).then(({ data }) => data);
};

export const impersonate = (impersonateData: ImpersonateRequest): Promise<LoginResponse> => {
  return httpApi.post<LoginResponse>('/auth/impersonate', { ...impersonateData }).then(({ data }) => data);
};
