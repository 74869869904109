import React, { useEffect, useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doReview } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Result } from '@app/components/common/Result/Result';
import { Rate } from '@app/components/common/Rate/Rate';
import * as S from './ReviewForm.styles';
import qs from 'qs';
// import ReactGA from 'react-ga4';
// import { Button } from '@app/components/common/buttons/Button/Button';

interface ReviewFormData {
  rating: string | number;
  review: string;
  registration_id: string | null;
}

export const initValues: ReviewFormData = {
  rating: 0,
  review: '',
  registration_id: localStorage.getItem('registrationId'),
};

export const ReviewForm: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [rating, setRating] = useState(0);

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true, // remove leading "?"
  });

  useEffect(() => {
    if (!localStorage.getItem('registrationId')) {
      setHasSubmitted(true);
    }

    // const qrSlug = queryParams?.view || localStorage.getItem('qrCodeSlug');
    //
    // if (qrSlug) {
    //   // ReactGA.send({ hitType: 'page-view', page: '/auth/review?qr=' + qrSlug, title: 'Review Page' });
    // }
  }, [queryParams?.view]);

  const handleSubmit = (values: ReviewFormData) => {
    setLoading(true);
    const payload = { ...values, rating: rating, registration_id: localStorage.getItem('registrationId') };
    dispatch(doReview(payload))
      .unwrap()
      .then(() => {
        setHasSubmitted(true);
        localStorage.removeItem('registrationId');
      })
      .catch(() => {
        notificationController.error({ message: 'Something went wrong, please try again!' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper style={{ backgroundColor: 'white' }}>
      {!hasSubmitted && (
        <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
          <Auth.FormTitle>How was your experience?</Auth.FormTitle>
          <S.Rating>
            <Rate value={rating} onChange={(value) => value && setRating(value)} style={{ fill: 'gray' }} />
          </S.Rating>
          <Auth.FormItem label="What's your feedaback?" name="review">
            <Auth.FormInput />
          </Auth.FormItem>
          <BaseForm.Item noStyle>
            <Auth.SubmitButton type="primary" htmlType="submit" disabled={rating === 0} loading={isLoading}>
              Submit
            </Auth.SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      )}

      {hasSubmitted && (
        <Result
          status="success"
          title={'Thank you!'}
          // extra={[
          //   <Button style={{ display: 'inline', width: '50%' }} onClick={() => window.close()} key="close">
          //     Close
          //   </Button>,
          // ]}
        />
      )}
    </Auth.FormWrapper>
  );
};
