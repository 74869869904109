import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { UserModel } from '@app/domain/UserModel';
import { persistUser, readUser } from '@app/services/localStorage.service';
import { currentUser, updatePassword } from '@app/api/user.api';

export interface UserState {
  user: UserModel | null;
}

export interface UpdatePasswordRequest {
  current_password: string;
  password: string;
  password_confirmation: string;
}

const initialState: UserState = {
  user: readUser(),
};

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  persistUser(newUser);

  return {
    payload: newUser,
  };
});

export const currentUserData = createAsyncThunk('/auth/current-user', async (payload, { dispatch }) =>
  currentUser().then((res) => {
    dispatch(setUser(res));
    return res;
  }),
);

export const updateUserPassword = createAsyncThunk(
  '/update-password',
  async (updatePasswordData: UpdatePasswordRequest) =>
    updatePassword(updatePasswordData).then((res) => {
      return res;
    }),
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default userSlice.reducer;
