import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ReviewPage from '@app/pages/ReviewPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import { withGuestLoading } from '@app/hocs/withGuestLoading.hoc';
import MatrixDashboardPage from '@app/pages/DashboardPages/MatrixDashboardPage';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const Logout = React.lazy(() => import('./Logout'));

const CompanyPage = React.lazy(() => import('@app/pages/matrix/CompanyPage'));
const QrCodePage = React.lazy(() => import('@app/pages/matrix/QrCodePage'));
const RegistrationPage = React.lazy(() => import('@app/pages/matrix/RegistrationPage'));
const AmenitiesPage = React.lazy(() => import('@app/pages/matrix/AmenitiesPage'));
const GLTFModalPage = React.lazy(() => import('@app/pages/matrix/GLTFModalPage'));
const ModelViewPage = React.lazy(() => import('@app/pages/matrix/ModelViewPage'));
const P3DModelViewerPage = React.lazy(() => import('@app/pages/matrix/P3DModelViewerPage'));

export const DASHBOARD_PATH = '/';

const MatrixDashboard = withLoading(MatrixDashboardPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withGuestLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

const Companies = withLoading(CompanyPage);
const QrCodes = withLoading(QrCodePage);
const Registrations = withLoading(RegistrationPage);
const Amenities = withLoading(AmenitiesPage);
const GLTFModal = withLoading(GLTFModalPage);
const ModelView = withLoading(ModelViewPage);
const P3DModelView = withLoading(P3DModelViewerPage);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<MatrixDashboard />} />
          <Route path="companies" element={<Companies />} />
          <Route path="qr-codes" element={<QrCodes />} />
          <Route path="amenities/:id" element={<Amenities />} />
          <Route path="gltf-modal/:id" element={<GLTFModal />} />
          <Route path="modal-view/:id" element={<ModelView />} />
          <Route path="registrations" element={<Registrations />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="payments" element={<Payments />} />
          </Route>
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="registration" element={<SignUpPage />} />
          <Route path="review" element={<ReviewPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="server-error" element={<ServerError />} />
        <Route path="p3d-model-viwer" element={<P3DModelView />} />
      </Routes>
    </BrowserRouter>
  );
};
